import Logo from "assets/logo.png";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "../../config/axios";
import toast, { Toaster } from "react-hot-toast";
import useAuth from "middlewares/hooks/useAuth";
const Register = () => {
    const [disabled, setDisabled] = useState(false);
    const nagivate = useNavigate();
    const { setAuth } = useAuth();
    const {
      register,
      handleSubmit,
      getValues,
      watch,
      formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
      try {
        setDisabled(true);
        const response = await axios.post("/register/new", data);
        const token = response?.data.data.token.token;
        setAuth({ token });
        nagivate("/verify");
      } catch (error) {
        if (!error?.response) {
          toast("No server response");
          setDisabled(false);
        } else {
          setDisabled(false);
          toast(error?.response?.data?.messages);
        }
      }
    }; 
  return (
    <>
      <Toaster />
      <div className="app app-login p-0">
        <div className="row g-0 app-auth-wrapper">
          <div className="col-12 col-md-5 col-lg-6 h-100 auth-background-col">
            <div className="auth-background-holder"></div>
            <div className="auth-background-mask"></div>
          </div>
          <div className="col-12 col-md-7 col-lg-6 auth-main-col text-center p-4">
            <div className="d-flex flex-column align-content-end">
              <div className="app-auth-body mx-auto">
                <div className="app-auth-branding mb-5">
                  <Link className="app-logo" to="/">
                    <img
                      className="me-2"
                      src={Logo}
                      alt="logo"
                      height={"100px"}
                    />
                  </Link>
                </div>

                <div className="card shadow-lg border-0 p-2 auth-form-container text-start">
                  <h4 className="card-header border-0 auth-heading text-center bg-white">
                    Sign Up For New Account
                  </h4>
                  <form
                    className="card-body auth-form login-form"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="email mb-3">
                      <label className="sr-only" htmlFor="firstname">
                        Firstname
                      </label>
                      <input
                        id="firstname"
                        name="firstname"
                        type="text"
                        className="form-control signin-email"
                        placeholder="Firstname"
                        autoComplete="off"
                        style={{ fontSize: "12px" }}
                        {...register("firstname", { required: true })}
                      />
                      {errors?.firstname?.type === "required" && (
                        <p className="text-small text-start px-1 text-danger help-block">
                          Please enter your firstname
                        </p>
                      )}
                    </div>
                    <div className="email mb-3">
                      <label className="sr-only" htmlFor="lastname">
                        Lastname
                      </label>
                      <input
                        id="lastname"
                        name="lastname"
                        type="text"
                        className="form-control signin-email"
                        placeholder="Lastname"
                        autoComplete="off"
                        style={{ fontSize: "12px" }}
                        {...register("lastname", { required: true })}
                      />
                      {errors?.lastname?.type === "required" && (
                        <p className="text-small text-start px-1 text-danger help-block">
                          Please enter your lastname
                        </p>
                      )}
                    </div>
                    <div className="email mb-3">
                      <label className="sr-only" htmlFor="contact">
                        Phone Number
                      </label>
                      <input
                        id="contact"
                        name="contact"
                        type="tel"
                        className="form-control signin-email"
                        placeholder="Phone Number"
                        autoComplete="off"
                        style={{ fontSize: "12px" }}
                        {...register("contact", { required: true })}
                      />
                      {errors?.contact?.type === "required" && (
                        <p className="text-small text-start px-1 text-danger help-block">
                          Please enter your contact
                        </p>
                      )}
                    </div>
                    <div className="password mb-3">
                      <label className="sr-only" htmlFor="password">
                        Password
                      </label>
                      <input
                        id="password"
                        name="password"
                        type="password"
                        className="form-control signin-password"
                        placeholder="Password"
                        autoComplete="current_password"
                        style={{ fontSize: "12px" }}
                        {...register("password", { required: true })}
                      />
                      {errors?.password?.type === "required" && (
                        <p className="text-small text-start px-1 text-danger help-block">
                          Please provide a password
                        </p>
                      )}
                    </div>
                    <div className="password mb-3">
                      <label className="sr-only" htmlFor="confirm_password">
                        Confirm Password
                      </label>
                      <input
                        id="confirm_password"
                        name="confirm_password"
                        type="password"
                        autoComplete="current_confirm_password"
                        className="form-control signin-password"
                        placeholder="Confirm Password"
                        style={{ fontSize: "12px" }}
                        {...register("password_confirmation", {
                          required: true,
                          validate: (value) => {
                            return value === watch("password") || false;
                          },
                        })}
                      />
                      {watch("password_confirmation") !== watch("password") &&
                      getValues("password_confirmation") ? (
                        <p className="text-small text-start px-1 text-danger help-block">
                          Password not match
                        </p>
                      ) : null}
                      {errors?.password_confirmation?.type === "required" && (
                        <p className="text-small text-start px-1 text-danger help-block">
                          Please confirm password
                        </p>
                      )}
                      <div className="extra mt-3 row justify-content-between">
                        <div className="col-12">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={true}
                              checked
                              id="AgreeConditions"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="AgreeConditions"
                            >
                              Agree To Terms & Conditions.
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                      <button
                        type="submit"
                        disabled={disabled}
                        className="btn app-btn-primary w-100 theme-btn mx-auto"
                      >
                        {!disabled && "Sign up"}{" "}
                        {disabled && "Please Wait ....."}
                      </button>
                    </div>
                  </form>

                  <div className="auth-option text-center pt-5">
                    Already Have An Account? Click{" "}
                    <Link className="text-link" to="/login">
                      here
                    </Link>
                    .
                  </div>
                </div>
              </div>

              <footer className="app-auth-footer">
                <div className="container text-center px-0 py-2">
                  <small className="copyright">
                    Developed By{" "}
                    <a
                      className="app-link"
                      href="https://hashtagtech.co"
                      target="_blank"
                      rel="noreferrer"
                    >
                      HashTag Techonologies
                    </a>{" "}
                  </small>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
