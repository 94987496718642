import Footer from "pages/home/components/footer";
import Header from "pages/home/components/header";
import Frame from "react-frame-component";

const Developer = () => {
    const gistUrl =
      "https://gist.github.com/Innocentenz/832436d41b68be0c7f55fffd7725268b.js";
    return (
      <>
        <Header />
        <div
          className="page-content py-5 container m-5"
          style={{ marginTop: "50px" }}
        >
          <div className="container">
            <h1>Send SMS Documentation</h1>

            <div style={{ marginTop: "20px" }}>
              <h2>Overview</h2>

              <div>
                <Frame
                  style={{ width: "100%", height: "350px" }}
                  initialContent={`<!DOCTYPE html><html><head></head><body><script src="${gistUrl}"></script></body></html>`}
                />
              </div>
              <p className="pt-5">
                The <code>sendsms</code> function simplifies the process of
                sending SMS messages through the SMS Garage SMS Gateway API. It
                takes a recipient's phone number and the message to be sent as
                parameters, using platform credentials (username and password)
                for authentication.
              </p>

              <h2>Usage</h2>

              <pre>
                <code>$result = sendsms($number, $message);</code>
              </pre>

              <h2>Parameters</h2>

              <ul>
                <li>
                  <strong>$number (string):</strong> The phone number of the
                  message recipient. It should be provided in international
                  format (e.g., +2567558744344).
                </li>
                <li>
                  <strong>$message (string):</strong> The content of the SMS
                  message you want to send.
                </li>
              </ul>

              <h2>Return Value</h2>

              <p>
                The function returns the response received from the SMS Gateway
                API.
              </p>

              <h2>Example</h2>

              <pre>
                <code>
                  $number = "+2567558744344";
                  <br />
                  $message = "Hello, this is a test message.";
                  <br />
                  $result = sendsms($number, $message);
                  <br />
                  echo "SMS Sent. Response: " . $result;
                </code>
              </pre>

              <h2>Notes</h2>

              <ul>
                <li>
                  Ensure that the provided phone number is in the correct
                  international format.
                </li>
                <li>
                  The message content should be URL-encoded using{" "}
                  <code>urlencode</code> to handle special characters
                  appropriately.
                </li>
                <li>
                  The function utilizes platform credentials (username and
                  password) for authentication. Keep these credentials secure
                  and do not expose them publicly.
                </li>
                <li>
                  The function constructs the API request URL with the specified
                  parameters, sends the request, and returns the API response.
                </li>
                <li>
                  The API response is a string containing the result of the SMS
                  sending operation.
                </li>
              </ul>

              <h2>Error Handling</h2>

              <p>
                If there are issues with the SMS sending process, the function
                may return an error response. It is recommended to implement
                error handling in your application to appropriately handle
                different response scenarios.
              </p>

              <h2>Security Considerations</h2>

              <ul>
                <li>
                  Always use HTTPS when making API requests to ensure the
                  security of transmitted data, especially when dealing with
                  sensitive information like platform credentials.
                </li>
                <li>
                  Keep platform credentials (username and password)
                  confidential. Avoid hardcoding them directly in your code if
                  possible.
                </li>
              </ul>

              <h2>Support</h2>

              <p>
                For any inquiries or assistance related to the SMS Gateway API,
                please contact{" "}
                <a href="mailto:info@smsgarages.com">info@smsgarages.com</a>
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
}
 
export default Developer;