import Footer from "pages/home/components/footer";
import Header from "pages/home/components/header";
import { Link } from "react-router-dom";
const ContactUs = () => {
  return (
    <>
      <Header />
      <div className="page-header theme-bg-dark py-5 text-center position-relative" style={{marginTop: "50px"}}>
        <div className="theme-bg-shapes-right"></div>
        <div className="theme-bg-shapes-left"></div>
        <div className="container">
          <h1 className="page-heading single-col-max mx-auto">Contact Us</h1>
          <div className="page-intro single-col-max mx-auto">
            Get in touch if you have any questions or want to get a custom quote
          </div>
        </div>
      </div>

      <div className="page-content py-5">
        <section className="options-section">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 mb-5">
                <div className="row contact-card shadow-sm p-4 rounded h-100 m-0 align-items-center">
                  <div className="col-4 p-0">
                    {/* <i className="fa fFa-phone"></i> */}
                  </div>

                  <div className="col">
                    <h5 className="mt-0">
                      <Link className="text-link" to="/contanctus">
                        Call Us On: +256 765 672 662
                        <i className="fas fa-long-arrow-alt-right ms-2"></i>
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 mb-5">
                <div className="row contact-card shadow-sm p-4 rounded h-100 m-0 align-items-center">
                  <div className="col-4 p-0">
                    {/* <i className="fa fa-code"></i> */}
                  </div>
                  <div className="col">
                    <h5 className="mt-0">
                      <Link className="text-link" to="/developer">
                        Documentation
                        <i className="fas fa-long-arrow-alt-right ms-2"></i>
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="contact-form-section mb-5">
          <div className="container">
            <form
              id="contact-form"
              className="contact-form p-5 col-lg-9 mx-lg-auto theme-bg-light shadow"
            >
              <h3 className="text-center">Ask A Question</h3>
              <div className="mb-3 text-center">
                For general questions, please use the form below.
              </div>
              <div className="row g-3">
                <div className="col-md-6">
                  <label className="sr-only" for="cname">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="cname"
                    name="name"
                    placeholder="Name"
                    minlength="2"
                    required=""
                  />
                </div>
                <div className="col-md-6">
                  <label className="sr-only" for="cemail">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="cemail"
                    name="email"
                    placeholder="Email"
                    required=""
                  />
                </div>

                <div className="col-12">
                  <label className="sr-only" for="cmessage">
                    Your message
                  </label>
                  <textarea
                    className="form-control"
                    id="cmessage"
                    name="message"
                    placeholder="Enter your message"
                    rows="10"
                    required=""
                  ></textarea>
                </div>
                <div className="col-12">
                  <button type="submit" className="btn w-100 text-white btn-primary py-2">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
