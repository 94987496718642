import Logo from "assets/logo.png";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "../../config/axios";
import toast, { Toaster } from "react-hot-toast";
import useAuth from "middlewares/hooks/useAuth";
const Verify = () => {
  const [disabled, setDisabled] = useState(false);
  const nagivate = useNavigate();
  const { auth } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    // console.log(data)
    try {
      setDisabled(true);
      await axios.post("/register/verify", data, {
        headers: { Authorization: auth?.token },
      });
      nagivate("/login");
    } catch (error) {
      if (!error?.response) {
        toast("No server response");
        setDisabled(false);
      } else {
        setDisabled(false);
        toast(error?.response?.data?.messages);
      }
    }
  };

  const [timer, setTimer] = useState(60); // Initial timer set to 60 seconds (1 minute)
  const [resendDisabled, setResendDisabled] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    let interval;
    if (timer > 0 && resendDisabled) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setResendDisabled(false);
    }

    return () => clearInterval(interval);
  }, [timer, resendDisabled, buttonDisabled]);

  const handleResendClick = () => {
    setResendDisabled(true);
    setButtonDisabled(true); // Disable the button after it's clicked
    setTimer((prevTimer) => prevTimer + 60); // Add one minute (60 seconds) to the timer value
  };

  const formatTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };
  return (
    <>
      <Toaster />
      <div className="app app-login p-0">
        <div className="row g-0 app-auth-wrapper">
          <div className="col-12 col-md-5 col-lg-6 h-100 auth-background-col">
            <div className="auth-background-holder"></div>
            <div className="auth-background-mask"></div>
          </div>
          <div className="col-12 col-md-7 col-lg-6 auth-main-col text-center p-4">
            <div className="d-flex flex-column align-content-end">
              <div className="app-auth-body mx-auto">
                <div className="app-auth-branding mb-5">
                  <Link className="app-logo" to="/">
                    <img
                      className="me-2"
                      src={Logo}
                      alt="logo"
                      height={"100px"}
                    />
                  </Link>
                </div>

                <div className="card shadow-lg border-0 p-2 auth-form-container text-start">
                  <h4 className="card-header border-0 auth-heading text-center bg-white">
                    Verify Account
                  </h4>
                  <form
                    className="card-body auth-form login-form"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="email mb-3">
                      <label className="sr-only" htmlFor="otpcode">
                        Enter OtpCode
                      </label>
                      <input
                        id="otpcode"
                        name="otpcode"
                        type="tel"
                        className="form-control signin-email"
                        placeholder="Enter OTP Code"
                        autoComplete="off"
                        style={{ fontSize: "12px" }}
                        {...register("otp", { required: true })}
                      />
                      {errors?.otp?.type === "required" && (
                        <p className="text-small text-center text-danger help-block">
                          Please enter your otp code
                        </p>
                      )}
                    </div>

                    <div className="text-center">
                      <button
                        type="submit"
                        disabled={disabled}
                        className="btn app-btn-primary w-100 theme-btn mx-auto"
                      >
                        {!disabled && "Verify"}{" "}
                        {disabled && "Please Wait ....."}
                      </button>
                    </div>
                  </form>

                  <div className="auth-option container text-center pt-5">
                    <p className="p-1 w-100">
                      Resend Verification code in: {formatTime()}
                    </p>
                    <button
                      onClick={handleResendClick}
                      disabled={resendDisabled || buttonDisabled}
                      className="btn btn-info text-white w-100 text-small"
                    >
                      Resend Verification Code
                    </button>
                  </div>
                </div>
              </div>

              <footer className="app-auth-footer">
                <div className="container text-center px-0 py-2">
                  <small className="copyright">
                    Developed By{" "}
                    <a
                      className="app-link"
                      href="https://hashtagtech.co"
                      target="_blank"
                      rel="noreferrer"
                    >
                      HashTag Techonologies
                    </a>{" "}
                  </small>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verify;
